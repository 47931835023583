* {
  box-sizing: border-box
}

body, html {
  height: 100%
}

body {
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  color: #424244;
  font-size: 1.5rem
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  line-height: 1.1;
  color: inherit
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-weight: 400;
  color: #a5a7aa
}

h1 {
  margin-top: 56px;
  margin-bottom: 9.5px;
  color: #50a546
}

h3 {
  color: #a5a7aa
}

p {
  line-height: 1.33333333
}

label {
  font-weight: 400;
}

code {
  color: #737476;
  background-color: #f5f5f5
}

.has-error code {
  color: #a94442;
  background-color: #f9f2f4
}

.container.full {
  margin: 0 auto;
  width: 100%
}

#header #banner.navbar .col-xs-12 {
  padding-left: 0;
  padding-right: 0
}

.dropdown-toggle {
  cursor: pointer;
}

@media (max-width: 767px) {
  .row-offcanvas {
    position: relative;
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    transition: all .25s ease-out
  }

  .row-offcanvas-left .sidebar-offcanvas {
    left: -33%
  }

  .sidebar-offcanvas {
    position: absolute;
    margin-top: 0;
    padding-left: 10px;
    width: 33%;
    background: #50a546;
    z-index: 99
  }
}

.navbar-inverse {
  background-color: #f3f3f4;
  border: 0;
  margin-bottom: 0;
  min-height: 36px
}

.navbar-inverse .btn-link {
  width: 100%;
  text-align: left;
  padding-left: 30px
}

.navbar-inverse .navbar-toggle {
  padding-top: 12px;
  padding-bottom: 12px;
  border-color: transparent
}

.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
  background-color: #3f8137
}

.navbar-inverse .navbar-nav > li > a {
  color: #737476;
  white-space: nowrap
}

@media (min-width: 768px) {
  .navbar-inverse .navbar-nav > li > a {
    padding-top: 8.5px;
    padding-bottom: 8.5px
  }
}

.navbar-inverse .navbar-nav > li > a:focus, .navbar-inverse .navbar-nav > li > a:hover {
  background-color: #fff;
  color: #50a546
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
  background-color: #e6e6e8;
  color: #50a546
}

.navbar-inverse .navbar-nav .btn-link, .navbar-inverse .navbar-nav .dropdown-menu > li > a {
  color: #333;
  padding: 3px 30px;
  font-weight: 400
}

.navbar-inverse .navbar-nav .btn-link:focus, .navbar-inverse .navbar-nav .btn-link:hover, .navbar-inverse .navbar-nav .dropdown-menu > li > a:focus, .navbar-inverse .navbar-nav .dropdown-menu > li > a:hover {
  text-decoration: none;
  color: #fff;
  background-color: #50a546
}

@media (max-width: 767px) {
  .navbar-inverse {
    background-color: #50a546
  }

  .navbar-inverse .navbar-brand {
    margin-top: 2px;
    padding: 0 15px
  }

  .navbar-inverse .navbar-nav {
    margin-top: 0;
    margin-bottom: 0
  }

  .navbar-inverse .navbar-nav .btn-link, .navbar-inverse .navbar-nav > li > a {
    color: #fff
  }

  .navbar-inverse .navbar-nav .btn-link:focus, .navbar-inverse .navbar-nav .btn-link:hover, .navbar-inverse .navbar-nav > li > a:focus, .navbar-inverse .navbar-nav > li > a:hover {
    color: #d1d2d4;
    background-color: #3f8137
  }

  .navbar-inverse .navbar-nav > .open .dropdown-menu > li > a, .navbar-inverse .navbar-nav > .open a {
    background-color: transparent;
    color: #fff
  }

  .navbar-inverse .navbar-nav > .open .dropdown-menu > li > a:focus, .navbar-inverse .navbar-nav > .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav > .open a:focus, .navbar-inverse .navbar-nav > .open a:hover {
    background-color: #3f8137;
    color: #d1d2d4
  }
}

.sidebar-offcanvas {
  height: 100%;
  margin-top: -37px
}

.sidebar-offcanvas .navbar-brand {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  height: auto
}

.sidebar-offcanvas .navbar-brand img {
  width: 100%;
  height: 100%
}

@media (min-width: 1200px) {
  .sidebar-offcanvas .navbar-brand {
    padding-top: 12px
  }
}

@media (min-width: 992px) {
  .sidebar-offcanvas .navbar-brand {
    padding-top: 16px
  }
}

@media (min-width: 768px) {
  .sidebar-offcanvas .navbar-brand {
    padding-top: 22px
  }
}

@media (min-width: 768px) {
  .section {
    display: flex;
    justify-content: space-between
  }

  .section .column {
    width: 45%
  }

  .section .column .block {
    min-height: 12.2em
  }
}

.main-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column
}

.main-container .main-content {
  flex: 1
}

#main > .col-xs-12 {
  max-width: 80em;
  max-width: 80rem
}

.screenshot {
  margin: 1em 0
}

.actions .form-group {
  margin-top: 40px;
  margin-bottom: 3em
}

#footer {
  min-height: 50px;
  background: #424244;
  box-shadow: inset 0 3px 10px 5px rgba(66, 66, 68, .6);
  color: #d1d2d4;
  font-size: 11px;
  font-size: 1.1rem
}

#footer .navbar {
  margin-bottom: 0
}

#footer a {
  color: #d1d2d4
}

#footer a:focus, #footer a:hover {
  color: #d1d2d4;
  text-decoration: underline;
  background-color: transparent
}

#footer .navbar-text {
  margin-top: 10px;
  margin-bottom: 10px
}

.checkbox-group {
  max-height: 17.25em;
  overflow: scroll;
  padding: 0 12px;
  background-color: transparent;
  overflow-wrap: break-word
}

.Select .is-focused:not(.is-open) > .Select-control, .Select:focus:not(.is-open) > .Select-control {
  outline: 0;
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6)
}

.has-error .Select:not(.is-open) .Select-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-error .Select.is-focused:not(.is-open) .Select-control, .has-error .Select:focus:not(.is-open) .Select-control {
  border-color: #843534;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483
}

.help-block:after {
  content: "\200B"
}

.radio .help-block {
  display: none
}

.radio .help-block:after {
  content: ""
}

.radio:last-of-type .help-block {
  display: block
}

.radio:last-of-type .help-block:after {
  content: "\200B"
}

details[open] summary:before {
  content: "\F0D7";
  margin-right: .425em
}

details summary {
  cursor: pointer;
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  padding: .5em 0;
  color: gray;
  font-style: italic;
  outline: 0
}

details summary::-webkit-details-marker {
  display: none
}

details summary:before {
  font-family: FontAwesome;
  font-style: normal;
  color: #d1d2d4;
  vertical-align: -10%;
  font-size: 1.33333333em;
  content: "\F0DA";
  margin-right: .625em
}

details p {
  padding: .4em 1.5em
}

.Select {
  position: relative
}

.Select, .Select div, .Select input, .Select span {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.Select.is-disabled > .Select-control {
  background-color: #f9f9f9
}

.Select.is-disabled > .Select-control:hover {
  box-shadow: none
}

.Select.is-disabled .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
  opacity: .35
}

.Select-control {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%
}

.Select-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, .06)
}

.Select-control .Select-input:focus {
  outline: none
}

.is-searchable.is-open > .Select-control {
  cursor: text
}

.is-open > .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9
}

.is-open > .Select-control > .Select-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px
}

.is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text
}

.is-focused:not(.is-open) > .Select-control {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 0 3px rgba(0, 126, 255, .1)
}

.Select--single > .Select-control .Select-value, .Select-placeholder {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label, .has-value.Select--single > .Select-control .Select-value .Select-value-label {
  color: #333
}

.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label, .has-value.Select--single > .Select-control .Select-value a.Select-value-label {
  cursor: pointer;
  text-decoration: none
}

.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus, .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover, .has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus, .has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover {
  color: #007eff;
  outline: none;
  text-decoration: underline
}

.Select-input {
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle
}

.Select-input > input {
  width: 100%;
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  line-height: 14px;
  padding: 8px 0 12px;
  -webkit-appearance: none
}

.is-focused .Select-input > input {
  cursor: text
}

.has-value.is-pseudo-focused .Select-input {
  opacity: 0
}

.Select-control:not(.is-searchable) > .Select-input {
  outline: none
}

.Select-loading-zone {
  cursor: pointer;
  display: table-cell;
  text-align: center
}

.Select-loading, .Select-loading-zone {
  position: relative;
  vertical-align: middle;
  width: 16px
}

.Select-loading {
  -webkit-animation: Select-animation-spin .4s infinite linear;
  -o-animation: Select-animation-spin .4s infinite linear;
  animation: Select-animation-spin .4s infinite linear;
  height: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-right-color: #333;
  display: inline-block
}

.Select-clear-zone {
  -webkit-animation: Select-animation-fadeIn .2s;
  -o-animation: Select-animation-fadeIn .2s;
  animation: Select-animation-fadeIn .2s;
  color: #999;
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 17px
}

.Select-clear-zone:hover {
  color: #d0021b
}

.Select-clear {
  display: inline-block;
  font-size: 18px;
  line-height: 1
}

.Select--multi .Select-clear-zone {
  width: 17px
}

.Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  padding-right: 5px
}

.Select-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0
}

.is-open .Select-arrow, .Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #666
}

.Select--multi .Select-multi-value-wrapper {
  display: inline-block
}

.Select .Select-aria-only {
  display: inline-block;
  height: 1px;
  width: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden
}

@-webkit-keyframes Select-animation-fadeIn {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes Select-animation-fadeIn {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

.Select-menu-outer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  -webkit-overflow-scrolling: touch
}

.Select-menu {
  max-height: 198px;
  overflow-y: auto
}

.Select-option {
  box-sizing: border-box;
  background-color: #fff;
  color: #666;
  cursor: pointer;
  display: block;
  padding: 8px 10px
}

.Select-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px
}

.Select-option.is-selected {
  background-color: #f5faff;
  background-color: rgba(0, 126, 255, .04);
  color: #333
}

.Select-option.is-focused {
  background-color: #ebf5ff;
  background-color: rgba(0, 126, 255, .08);
  color: #333
}

.Select-option.is-disabled {
  color: #ccc;
  cursor: default
}

.Select-noresults {
  box-sizing: border-box;
  color: #999;
  cursor: default;
  display: block;
  padding: 8px 10px
}

.Select--multi .Select-input {
  vertical-align: middle;
  margin-left: 10px;
  padding: 0
}

.Select--multi.has-value .Select-input {
  margin-left: 5px
}

.Select--multi .Select-value {
  background-color: #ebf5ff;
  background-color: rgba(0, 126, 255, .08);
  border-radius: 2px;
  border: 1px solid #c2e0ff;
  border: 1px solid rgba(0, 126, 255, .24);
  color: #007eff;
  display: inline-block;
  font-size: .9em;
  line-height: 1.4;
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top
}

.Select--multi .Select-value-icon, .Select--multi .Select-value-label {
  display: inline-block;
  vertical-align: middle
}

.Select--multi .Select-value-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default;
  padding: 2px 5px
}

.Select--multi a.Select-value-label {
  color: #007eff;
  cursor: pointer;
  text-decoration: none
}

.Select--multi a.Select-value-label:hover {
  text-decoration: underline
}

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-right: 1px solid #c2e0ff;
  border-right: 1px solid rgba(0, 126, 255, .24);
  padding: 1px 5px 3px
}

.Select--multi .Select-value-icon:focus, .Select--multi .Select-value-icon:hover {
  background-color: #d8eafd;
  background-color: rgba(0, 113, 230, .08);
  color: #0071e6
}

.Select--multi .Select-value-icon:active {
  background-color: #c2e0ff;
  background-color: rgba(0, 126, 255, .24)
}

.Select--multi.is-disabled .Select-value {
  background-color: #fcfcfc;
  border: 1px solid #e3e3e3;
  color: #333
}

.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid #e3e3e3
}

.Select--multi.is-disabled .Select-value-icon:active, .Select--multi.is-disabled .Select-value-icon:focus, .Select--multi.is-disabled .Select-value-icon:hover {
  background-color: #fcfcfc
}

@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn)
  }
}

@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn)
  }
}

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer
}

button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

input {
  line-height: normal
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0
}

.google-button {
  height: 34px;
  padding: 0 12px 0 0;
  position: relative
}

.google-button span {
  display: inline-block;
  vertical-align: middle
}

.google-icon {
  padding: 20px 24px 12px 8px;
  margin-right: 12px;
  border-radius: 3px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  position: relative;
  background: #fff none
}

.google-icon img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 8px
}

.btn {
  font-weight: 600
}

.btn.focus, .btn:focus, .btn:hover {
  color: #424244;
  text-decoration: none
}

.btn-danger, .btn-default, .btn-info, .btn-primary, .btn-success, .btn-warning {
  box-shadow: 0 1px 3px rgba(0, 0, 0, .4), inset 0 0 1px hsla(0, 0%, 100%, .7);
  text-transform: uppercase;
  letter-spacing: .25px
}

.btn-primary {
  color: #fff;
  background-color: #50a546;
  border-color: #47933e
}

.btn-primary.active, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary.focus, .btn-primary:active, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary:focus, .btn-primary:hover {
  color: #fff;
  background-color: #3f8137;
  border-color: #32682c
}

.btn-primary[disabled]:focus, .btn-primary[disabled]:hover {
  color: #fff;
  background-color: #50a546;
  border-color: #47933e
}

.btn-link {
  color: #5975a2
}

.btn-link.btn-no-pad {
  padding: 0;
  vertical-align: baseline
}

.btn-link:active, .btn-link:active:focus, .btn-link:focus {
  outline: 0
}

.btn-link:focus, .btn-link:hover {
  color: #3e5171;
  text-decoration: underline
}

.btn-no-padding {
  padding: 0;
}

.btn-remove {
  position: absolute;
  top: -6px;
  left: 0;
  transform: translateY(50%);
}

.long-heading {
  width: calc(100vw - 50%);
}

.async-validating {
  background: url(../images/loader.gif) no-repeat right 10px center;
  padding-right: 40px;
}
